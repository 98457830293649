import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="A diagram of three avatars stacked next to each other." src="https://user-images.githubusercontent.com/586552/203208345-b110f45e-f7e5-4fab-b972-f5d31e809f22.png" />
    <h2>{`Usage`}</h2>
    <p>{`Stacked avatars can be used to show multiple collaborators or participants when there is limited space available. When you hover over the stack, the avatars will reveal themselves.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Use AvatarStack when space is limited`}</li>
      <li parentName="ul">{`Don’t use AvatarStack if there is adequate space to show 4 avatars or less.`}</li>
    </ul>
    <h2>{`Anatomy`}</h2>
    <p>{`Avatar stack displays a minimum of 2 avatars and a maximum of 4 avatars.
Only the 20px avatar size is currently used in the avatar stack.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`Alignment`}</h3>
    <p>{`The default avatar stack is left-aligned. You can right-align the component for layouts that are better suited for it.`}</p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="AvatarStack" mdxType="AccessibilityLink" />
    <h2>{`Related components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/avatar"
        }}>{`Avatar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/avatar-pair"
        }}>{`Avatar pair`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      